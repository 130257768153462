// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as CoursesCurriculum from "../courses/curricula/components/CoursesCurriculum.bs.js";
import * as EvaluationCriterion from "../shared/types/EvaluationCriterion.bs.js";
import * as CoursesCurriculum__User from "../courses/curricula/types/CoursesCurriculum__User.bs.js";
import * as CoursesCurriculum__Coach from "../courses/curricula/types/CoursesCurriculum__Coach.bs.js";
import * as CoursesCurriculum__Level from "../courses/curricula/types/CoursesCurriculum__Level.bs.js";
import * as CoursesCurriculum__Course from "../courses/curricula/types/CoursesCurriculum__Course.bs.js";
import * as CoursesCurriculum__Target from "../courses/curricula/types/CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Student from "../courses/curricula/types/CoursesCurriculum__Student.bs.js";
import * as CoursesCurriculum__TargetGroup from "../courses/curricula/types/CoursesCurriculum__TargetGroup.bs.js";
import * as CoursesCurriculum__LatestSubmission from "../courses/curricula/types/CoursesCurriculum__LatestSubmission.bs.js";

function decodeProps(json) {
  return [
          Json_decode.field("author", Json_decode.bool, json),
          Json_decode.field("course", CoursesCurriculum__Course.decode, json),
          Json_decode.field("levels", (function (param) {
                  return Json_decode.array(CoursesCurriculum__Level.decode, param);
                }), json),
          Json_decode.field("targetGroups", (function (param) {
                  return Json_decode.array(CoursesCurriculum__TargetGroup.decode, param);
                }), json),
          Json_decode.field("targets", (function (param) {
                  return Json_decode.array(CoursesCurriculum__Target.decode, param);
                }), json),
          Json_decode.field("submissions", (function (param) {
                  return Json_decode.array(CoursesCurriculum__LatestSubmission.decode, param);
                }), json),
          Json_decode.field("targetsRead", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          Json_decode.field("student", CoursesCurriculum__Student.decode, json),
          Json_decode.field("coaches", (function (param) {
                  return Json_decode.array(CoursesCurriculum__Coach.decode, param);
                }), json),
          Json_decode.field("users", (function (param) {
                  return Json_decode.array(CoursesCurriculum__User.decode, param);
                }), json),
          Json_decode.field("evaluationCriteria", (function (param) {
                  return Json_decode.array(EvaluationCriterion.decode, param);
                }), json),
          Json_decode.field("preview", Json_decode.bool, json),
          Json_decode.field("accessLockedLevels", Json_decode.bool, json)
        ];
}

Psj.matchPaths(undefined, [
      "courses/:id/curriculum",
      "targets/:id",
      "targets/:id/:slug"
    ], (function (param) {
        var match = decodeProps(DomUtils.parseJSONTag(undefined, undefined));
        var root = document.querySelector("#react-root");
        if (!(root == null)) {
          ReactDom.render(React.createElement(CoursesCurriculum.make, {
                    author: match[0],
                    course: match[1],
                    levels: match[2],
                    targetGroups: match[3],
                    targets: match[4],
                    submissions: match[5],
                    student: match[7],
                    coaches: match[8],
                    users: match[9],
                    evaluationCriteria: match[10],
                    preview: match[11],
                    accessLockedLevels: match[12],
                    targetsRead: match[6]
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
}
/*  Not a pure module */
