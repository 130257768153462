// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as CoursesCurriculum__Level from "./CoursesCurriculum__Level.bs.js";
import * as CoursesCurriculum__Course from "./CoursesCurriculum__Course.bs.js";
import * as CoursesCurriculum__Target from "./CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Student from "./CoursesCurriculum__Student.bs.js";
import * as CoursesCurriculum__TargetGroup from "./CoursesCurriculum__TargetGroup.bs.js";
import * as CoursesCurriculum__LatestSubmission from "./CoursesCurriculum__LatestSubmission.bs.js";

var partial_arg = "components.CoursesCurriculum__TargetStatus";

function tc(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function isPast(date) {
  return Belt_Option.mapWithDefault(date, false, DateFns.isPast);
}

function makePending(targets) {
  return Js_array.map((function (t) {
                return {
                        targetId: CoursesCurriculum__Target.id(t),
                        status: /* Pending */0
                      };
              }), targets);
}

function lockTargets(targets, reason) {
  return Js_array.map((function (t) {
                return {
                        targetId: CoursesCurriculum__Target.id(t),
                        status: /* Locked */{
                          _0: reason
                        }
                      };
              }), targets);
}

function allTargetsAttempted(targetCache, targetIds) {
  return Belt_Array.every(targetIds, (function (targetId) {
                return Belt_Option.mapWithDefault(Js_array.find((function (ct) {
                                  return ct.targetId === targetId;
                                }), targetCache), true, (function (target) {
                              return target.submissionStatus !== /* SubmissionMissing */0;
                            }));
              }));
}

function compute(preview, student, course, levels, targetGroups, targets, targetsRead, submissions) {
  if (preview) {
    return makePending(targets);
  }
  if (CoursesCurriculum__Course.ended(course)) {
    return lockTargets(targets, /* CourseLocked */0);
  }
  var date = CoursesCurriculum__Student.endsAt(student);
  if (Belt_Option.mapWithDefault(date, false, DateFns.isPast)) {
    return lockTargets(targets, /* AccessLocked */1);
  }
  var targetCache = Js_array.map((function (target) {
          var targetId = CoursesCurriculum__Target.id(target);
          var targetGroup = ArrayUtils.unsafeFind((function (tg) {
                  return CoursesCurriculum__TargetGroup.id(tg) === CoursesCurriculum__Target.targetGroupId(target);
                }), "Could not find target group with ID " + (CoursesCurriculum__Target.targetGroupId(target) + " to create target cache"), targetGroups);
          var levelNumber = CoursesCurriculum__Level.number(ArrayUtils.unsafeFind((function (l) {
                      return CoursesCurriculum__Level.id(l) === CoursesCurriculum__TargetGroup.levelId(targetGroup);
                    }), "Could not find level with ID " + (CoursesCurriculum__Student.levelId(student) + " to create target cache"), levels));
          var submission = Js_array.find((function (s) {
                  return CoursesCurriculum__LatestSubmission.targetId(s) === targetId;
                }), submissions);
          var submissionStatus = submission !== undefined ? (
              CoursesCurriculum__LatestSubmission.hasPassed(submission) ? /* SubmissionCompleted */2 : (
                  CoursesCurriculum__LatestSubmission.hasBeenEvaluated(submission) ? /* SubmissionRejected */3 : /* SubmissionPendingReview */1
                )
            ) : /* SubmissionMissing */0;
          return {
                  targetId: targetId,
                  targetReviewed: CoursesCurriculum__Target.reviewed(target),
                  levelNumber: levelNumber,
                  milestone: CoursesCurriculum__Target.milestone(target),
                  hasAssignment: CoursesCurriculum__Target.hasAssignment(target),
                  submissionStatus: submissionStatus,
                  prerequisiteTargetIds: CoursesCurriculum__Target.prerequisiteTargetIds(target)
                };
        }), targets);
  var submissionsPendingReviewCount = Js_array.filter((function (ct) {
          return ct.submissionStatus === /* SubmissionPendingReview */1;
        }), targetCache).length;
  return Js_array.map((function (ct) {
                var match = ct.submissionStatus;
                var status;
                switch (match) {
                  case /* SubmissionMissing */0 :
                      status = ct.targetReviewed && CoursesCurriculum__Course.progressionLimit(course) !== 0 && submissionsPendingReviewCount >= CoursesCurriculum__Course.progressionLimit(course) ? /* Locked */({
                            _0: /* SubmissionLimitReached */{
                              _0: String(submissionsPendingReviewCount)
                            }
                          }) : (
                          allTargetsAttempted(targetCache, ct.prerequisiteTargetIds) ? (
                              !ct.hasAssignment && Js_array.includes(ct.targetId, targetsRead) ? /* Completed */2 : /* Pending */0
                            ) : /* Locked */({
                                _0: /* PrerequisitesIncomplete */2
                              })
                        );
                      break;
                  case /* SubmissionPendingReview */1 :
                      status = /* PendingReview */1;
                      break;
                  case /* SubmissionCompleted */2 :
                      status = /* Completed */2;
                      break;
                  case /* SubmissionRejected */3 :
                      status = /* Rejected */3;
                      break;
                  
                }
                return {
                        targetId: ct.targetId,
                        status: status
                      };
              }), targetCache);
}

function targetId(t) {
  return t.targetId;
}

function status(t) {
  return t.status;
}

function isPending(t) {
  return t.status === /* Pending */0;
}

function isAccessEnded(t) {
  var reason = t.status;
  if (typeof reason === "number") {
    return false;
  } else {
    return reason._0 === /* AccessLocked */1;
  }
}

function lockReasonToString(lockReason) {
  if (typeof lockReason !== "number") {
    return tc([[
                  "pending_count",
                  lockReason._0
                ]], undefined, "submission_limit_reached");
  }
  switch (lockReason) {
    case /* CourseLocked */0 :
        return tc(undefined, undefined, "course_locked");
    case /* AccessLocked */1 :
        return tc(undefined, undefined, "access_locked");
    case /* PrerequisitesIncomplete */2 :
        return tc(undefined, undefined, "prerequisites_incomplete");
    
  }
}

function statusToString(t) {
  var match = t.status;
  if (typeof match !== "number") {
    return tc(undefined, undefined, "status.locked");
  }
  switch (match) {
    case /* Pending */0 :
        return tc(undefined, undefined, "status.pending");
    case /* PendingReview */1 :
        return tc(undefined, undefined, "status.pending_review");
    case /* Completed */2 :
        return tc(undefined, undefined, "status.completed");
    case /* Rejected */3 :
        return tc(undefined, undefined, "status.rejected");
    
  }
}

function statusClassesSufix(t) {
  var match = t.status;
  if (typeof match !== "number") {
    return "locked";
  }
  switch (match) {
    case /* Pending */0 :
        return "pending";
    case /* PendingReview */1 :
        return "pending-review";
    case /* Completed */2 :
        return "completed";
    case /* Rejected */3 :
        return "rejected";
    
  }
}

function canSubmit(resubmittable, t) {
  var match = t.status;
  if (resubmittable) {
    if (match === 2) {
      return true;
    }
    
  } else if (match === 2) {
    return false;
  }
  if (typeof match === "number") {
    return match !== 1;
  } else {
    return false;
  }
}

function allAttempted(ts) {
  return Belt_Array.every(ts, (function (t) {
                return Js_array.includes(t.status, [
                            /* PendingReview */1,
                            /* Completed */2,
                            /* Rejected */3
                          ]);
              }));
}

function allComplete(ts) {
  return Belt_Array.every(ts, (function (t) {
                return t.status === /* Completed */2;
              }));
}

function anyRejected(ts) {
  return Belt_Array.some(ts, (function (t) {
                return t.status === /* Rejected */3;
              }));
}

var Course;

var Student;

var Target;

var Level;

var TargetGroup;

var LatestSubmission;

export {
  Course ,
  Student ,
  Target ,
  Level ,
  TargetGroup ,
  LatestSubmission ,
  tc ,
  isPast ,
  makePending ,
  lockTargets ,
  allTargetsAttempted ,
  compute ,
  targetId ,
  status ,
  isPending ,
  isAccessEnded ,
  lockReasonToString ,
  statusToString ,
  statusClassesSufix ,
  canSubmit ,
  allAttempted ,
  allComplete ,
  anyRejected ,
}
/* I18n Not a pure module */
