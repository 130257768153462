// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as ContentBlock from "../../../shared/types/ContentBlock.bs.js";
import * as TargetChecklistItem from "../../../schools/courses/types/curriculum_editor/TargetChecklistItem.bs.js";
import * as CoursesCurriculum__Grade from "./CoursesCurriculum__Grade.bs.js";
import * as CoursesCurriculum__Feedback from "./CoursesCurriculum__Feedback.bs.js";
import * as CoursesCurriculum__Community from "./CoursesCurriculum__Community.bs.js";
import * as CoursesCurriculum__Submission from "./CoursesCurriculum__Submission.bs.js";
import * as CoursesCurriculum__QuizQuestion from "./CoursesCurriculum__QuizQuestion.bs.js";

function submissions(t) {
  return t.submissions;
}

function pendingUserIds(t) {
  return t.pendingUserIds;
}

function feedback(t) {
  return t.feedback;
}

function navigation(t) {
  return [
          t.navigation.previous,
          t.navigation.next
        ];
}

function checklist(t) {
  return t.checklist;
}

function decodeNavigation(json) {
  return {
          previous: Json_decode.optional((function (param) {
                  return Json_decode.field("previous", Json_decode.string, param);
                }), json),
          next: Json_decode.optional((function (param) {
                  return Json_decode.field("next", Json_decode.string, param);
                }), json)
        };
}

function decode(json) {
  return {
          pendingUserIds: Json_decode.field("pendingUserIds", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          submissions: Json_decode.field("submissions", (function (param) {
                  return Json_decode.array(CoursesCurriculum__Submission.decode, param);
                }), json),
          feedback: Json_decode.field("feedback", (function (param) {
                  return Json_decode.array(CoursesCurriculum__Feedback.decode, param);
                }), json),
          quizQuestions: Json_decode.field("quizQuestions", (function (param) {
                  return Json_decode.array(CoursesCurriculum__QuizQuestion.decode, param);
                }), json),
          contentBlocks: Json_decode.field("contentBlocks", (function (param) {
                  return Json_decode.array(ContentBlock.decode, param);
                }), json),
          communities: Json_decode.field("communities", (function (param) {
                  return Json_decode.array(CoursesCurriculum__Community.decode, param);
                }), json),
          evaluated: Json_decode.field("evaluated", Json_decode.bool, json),
          grading: Json_decode.field("grading", (function (param) {
                  return Json_decode.array(CoursesCurriculum__Grade.decode, param);
                }), json),
          completionInstructions: Caml_option.null_to_opt(Json_decode.field("completionInstructions", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json)),
          navigation: Json_decode.field("navigation", decodeNavigation, json),
          checklist: Json_decode.field("checklist", (function (param) {
                  return Json_decode.array(TargetChecklistItem.decode, param);
                }), json)
        };
}

function computeCompletionType(targetDetails) {
  var evaluated = targetDetails.evaluated;
  var hasQuiz = targetDetails.quizQuestions.length > 0;
  var hasChecklist = targetDetails.checklist.length > 0;
  if (evaluated) {
    return /* Evaluated */0;
  } else if (hasQuiz) {
    return /* TakeQuiz */1;
  } else if (hasChecklist) {
    return /* SubmitForm */2;
  } else {
    return /* NoAssignment */3;
  }
}

function contentBlocks(t) {
  return t.contentBlocks;
}

function quizQuestions(t) {
  return t.quizQuestions;
}

function communities(t) {
  return t.communities;
}

function completionInstructions(t) {
  return t.completionInstructions;
}

function grades(submissionId, t) {
  return Js_array.filter((function (grade) {
                return CoursesCurriculum__Grade.submissionId(grade) === submissionId;
              }), t.grading);
}

function addSubmission(submission, t) {
  return {
          pendingUserIds: t.pendingUserIds,
          submissions: Js_array.concat([submission], t.submissions),
          feedback: t.feedback,
          quizQuestions: t.quizQuestions,
          contentBlocks: t.contentBlocks,
          communities: t.communities,
          evaluated: t.evaluated,
          grading: t.grading,
          completionInstructions: t.completionInstructions,
          navigation: t.navigation,
          checklist: t.checklist
        };
}

function clearPendingUserIds(t) {
  return {
          pendingUserIds: [],
          submissions: t.submissions,
          feedback: t.feedback,
          quizQuestions: t.quizQuestions,
          contentBlocks: t.contentBlocks,
          communities: t.communities,
          evaluated: t.evaluated,
          grading: t.grading,
          completionInstructions: t.completionInstructions,
          navigation: t.navigation,
          checklist: t.checklist
        };
}

export {
  submissions ,
  pendingUserIds ,
  feedback ,
  navigation ,
  checklist ,
  decodeNavigation ,
  decode ,
  computeCompletionType ,
  contentBlocks ,
  quizQuestions ,
  communities ,
  completionInstructions ,
  grades ,
  addSubmission ,
  clearPendingUserIds ,
}
/* ContentBlock Not a pure module */
